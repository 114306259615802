import {
    CardProps,
    Card,
    type CardMediaProps,
    Box,
    type CardActionAreaProps,
    CardActionArea,
    type SxProps
} from '@mui/material'
import React, { type ReactElement, type ReactNode, useMemo } from 'react'
import { type ExtendedTheme } from '../../../../theme'

export interface LeafBaseMediaCardProps extends CardProps {
    media: ReactElement<CardMediaProps>
    flexMedia?: boolean
    actionAreaProps?: CardActionAreaProps
    alignMedia?: 'top' | 'left' | 'right'
    mediaWidth?: string
    mediaHeight?: string
}

const commonPadding = 3

interface ChildrenContainerProps {
    width?: string
    flexDirection?: 'row' | 'column'
    children: ReactNode
}
function ChildrenContainer({
    children,
    width,
    flexDirection
}: ChildrenContainerProps) {
    return (
        <Box
            data-testid="BaseMediaCardChildrenContainer"
            sx={{
                '& > :last-of-type': {
                    paddingBottom: 0
                },
                '& > :first-of-type': {
                    paddingTop: 0
                },
                // Hack because we are wrapping elements in Stacks everywhere
                '.MuiStack-root': {
                    '& > :last-of-type': {
                        paddingBottom: 0
                    },
                    '& > :first-of-type': {
                        paddingTop: 0
                    }
                },
                ...(width && { width }),
                ...(flexDirection && { flexDirection }),
                padding: commonPadding
            }}
        >
            {children}
        </Box>
    )
}

function TopAlignedContent({
    media,
    children,
    actionAreaProps
}: {
    media: ReactElement<CardMediaProps>
    children: ReactNode
    actionAreaProps?: CardActionAreaProps
}) {
    if (actionAreaProps) {
        return (
            <CardActionArea
                data-testid="BaseMediaCardTopActionArea"
                {...actionAreaProps}
            >
                {media}
                <ChildrenContainer>{children}</ChildrenContainer>
            </CardActionArea>
        )
    }
    return (
        <>
            {media}
            <ChildrenContainer>{children}</ChildrenContainer>
        </>
    )
}

function SideAlignedMedia({
    media,
    flexMedia
}: {
    media: ReactElement<CardMediaProps>
    flexMedia?: boolean
}) {
    if (flexMedia) {
        return (
            <div
                data-testid="BaseMediaCareSideAlignedFlexMedia"
                style={{ height: '100%' }}
            >
                {media}
            </div>
        )
    }
    return media
}

function SideAlignedContent({
    media,
    children,
    actionAreaProps,
    flexMedia,
    mediaWidth
}: {
    media: ReactElement<CardMediaProps>
    children: ReactNode
    actionAreaProps?: CardActionAreaProps
    flexMedia?: boolean
    mediaWidth?: string
}) {
    if (actionAreaProps !== undefined) {
        return (
            <>
                <CardActionArea
                    data-testid="BaseMediaCardSideAlignedContentActionArea"
                    {...actionAreaProps}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        '.MuiCardMedia-root': { height: '100%' }
                    }}
                >
                    <SideAlignedMedia media={media} flexMedia={flexMedia} />
                    <ChildrenContainer
                        width={
                            flexMedia ? '100%' : `calc(100% - ${mediaWidth})`
                        }
                    >
                        {children}
                    </ChildrenContainer>
                </CardActionArea>
            </>
        )
    }

    return (
        <>
            {media}
            <ChildrenContainer
                flexDirection={'column'}
                width={flexMedia ? '100%' : `calc(100% - ${mediaWidth})`}
            >
                {children}
            </ChildrenContainer>
        </>
    )
}

const LeafBaseMediaCard: React.FC<LeafBaseMediaCardProps> = ({
    media,
    flexMedia = false,
    actionAreaProps,
    alignMedia = 'left',
    mediaWidth = flexMedia ? 'fit-content' : '50%',
    mediaHeight = '150px',
    ...props
}) => {
    const { children, sx, ...otherCardProps } = props

    const isRightAligned = alignMedia === 'right'
    const isTopAligned = alignMedia === 'top'

    const styles = useMemo((): SxProps<ExtendedTheme> => {
        const flexDirection = isTopAligned
            ? 'column'
            : isRightAligned
              ? 'row-reverse'
              : 'row'
        const noSidePadding = { paddingLeft: 0, paddingRight: 0 }
        // The overrides for height in CardActionArea and CardContent are necessary to explicitly set the height of the card
        const actionAreaStyles = isTopAligned
            ? { height: 'inherit', ...noSidePadding }
            : { ...noSidePadding }
        const contentStyles = isTopAligned
            ? { height: `calc(100% - ${mediaHeight})`, ...noSidePadding }
            : { ...noSidePadding }
        const headingStyles = { ...noSidePadding }
        const actionsRootStyles = { ...noSidePadding }
        const mediaStyles = isTopAligned
            ? {
                  minHeight: mediaHeight,
                  marginBottom: 0
              }
            : {
                  width: mediaWidth,
                  padding: flexMedia ? commonPadding : 0,
                  marginRight: !isRightAligned ? 0 : undefined,
                  marginLeft: isRightAligned ? 0 : undefined
              }
        return {
            ...sx,
            display: 'flex',
            flexDirection,
            '.MuiCardActions-root': actionsRootStyles,
            '.MuiCardActionArea-root': actionAreaStyles,
            '.MuiCardContent-root': contentStyles,
            '.MuiCardMedia-root': mediaStyles,
            '.MuiCardHeader-root': headingStyles
        }
    }, [sx, isTopAligned, isRightAligned, mediaHeight, mediaWidth, flexMedia])
    return (
        <Card {...otherCardProps} sx={styles}>
            {isTopAligned && (
                <TopAlignedContent
                    media={media}
                    actionAreaProps={actionAreaProps}
                >
                    {children}
                </TopAlignedContent>
            )}
            {!isTopAligned && (
                <SideAlignedContent
                    media={media}
                    actionAreaProps={actionAreaProps}
                    flexMedia={flexMedia}
                    mediaWidth={mediaWidth}
                >
                    {children}
                </SideAlignedContent>
            )}
        </Card>
    )
}

export default LeafBaseMediaCard
