/* eslint-disable jsx-a11y/media-has-caption */
import { captureException, captureMessage } from '@thriveglobal/thrive-web-core'
import Hls from 'hls.js'
import React, {
    PropsWithChildren,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo
} from 'react'
import { useSyncStateWithRef } from '../../../../hooks'
import { IframePlayerProps } from './IframePlayer'
import useProperty from './useProperty'
import { parseStartTime } from './utils'

// Stream player facade for the HLS player
export type HLSPlayerProps = PropsWithChildren<IframePlayerProps>

function HLSPlayer(
    props: HLSPlayerProps,
    ref: React.ForwardedRef<HTMLVideoElement>
): JSX.Element {
    const {
        src,
        controls = false,
        muted = false,
        autoplay = false,
        loop = false,
        preload = 'metadata',
        height,
        width,
        currentTime = 0,
        volume = 1,
        className,
        poster,
        startTime,
        defaultTextTrack,
        children,
        onAbort,
        onCanPlay,
        onCanPlayThrough,
        onDurationChange,
        onEnded,
        onError,
        onLoadedData,
        onLoadedMetaData,
        onLoadStart,
        onPause,
        onPlay,
        onPlaying,
        onProgress,
        onRateChange,
        onSeeked,
        onSeeking,
        onStalled,
        onSuspend,
        onTimeUpdate,
        onVolumeChange,
        onWaiting
    } = props

    const videoRef = React.useRef<HTMLVideoElement>(null)
    const hlsRef = React.useRef<Hls | null>(null)

    const onLoadedMetaDataRef = useSyncStateWithRef(onLoadedMetaData)
    const onErrorRef = useSyncStateWithRef(onError)
    const starTimeRef = useSyncStateWithRef(startTime)
    const defaultTextTrackRef = useSyncStateWithRef(defaultTextTrack)

    const preloadValue = useMemo(
        () =>
            preload === true ? 'auto' : preload === false ? 'none' : preload,
        [preload]
    )

    useEffect(() => {
        if (ref) {
            if (typeof ref === 'function') {
                ref(videoRef.current)
            } else {
                ref.current = videoRef.current
            }
        }
    }, [ref])

    // Update text track
    useEffect(() => {
        if (!hlsRef.current) {
            return
        }

        if (!defaultTextTrack) {
            hlsRef.current.subtitleDisplay = false
            return
        }

        const track = hlsRef.current.subtitleTracks?.find(
            (data) => data.lang === defaultTextTrack
        )

        if (track) {
            hlsRef.current.setSubtitleOption(track)
            hlsRef.current.subtitleDisplay = true
        }
    }, [defaultTextTrack])

    useEffect(() => {
        if (src) {
            hlsRef.current = new Hls()
            const hls = hlsRef.current

            hls.loadSource(src)
            hls.attachMedia(videoRef.current)

            hls.on(Hls.Events.MEDIA_ATTACHED, (event, data) => {
                const startTimeValue = starTimeRef.current

                if (startTimeValue) {
                    try {
                        if (typeof startTimeValue === 'string') {
                            videoRef.current.currentTime =
                                parseStartTime(startTimeValue)
                        } else if (typeof startTimeValue === 'number') {
                            videoRef.current.currentTime = startTimeValue
                        }
                    } catch (e) {
                        captureException(
                            new Error(
                                `Failed to set HLS Player start time to "${startTimeValue}": ${e}`
                            )
                        )
                    }
                }
            })

            hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
                onLoadedMetaDataRef.current?.(
                    new CustomEvent(Hls.Events.MANIFEST_PARSED, {
                        detail: data
                    })
                )

                // Set default text track
                if (hls && defaultTextTrackRef.current) {
                    const track = data.subtitleTracks?.find(
                        (track) => track.lang === defaultTextTrackRef.current
                    )

                    if (track) {
                        hls.setSubtitleOption(track)
                        hls.subtitleDisplay = true
                    }
                }
            })

            hls.on(Hls.Events.ERROR, (event, data) => {
                if (!hls) {
                    return
                }

                const errorType = data.type
                const errorDetails = data.details

                if (data.fatal) {
                    switch (data.type) {
                        case Hls.ErrorTypes.MEDIA_ERROR:
                            captureException(
                                new Error(
                                    'Fatal HLS media error encountered, try to recover'
                                ),
                                { errorType, errorDetails }
                            )
                            hls.recoverMediaError()
                            break
                        case Hls.ErrorTypes.NETWORK_ERROR:
                            captureException(
                                new Error(
                                    'Fatal HLS network error encountered'
                                ),
                                {
                                    errorType,
                                    errorDetails
                                }
                            )
                            // All retries and media options have been exhausted.
                            // Immediately trying to restart loading could cause loop loading.
                            // Consider modifying loading policies to best fit your asset and network
                            // conditions (manifestLoadPolicy, playlistLoadPolicy, fragLoadPolicy).
                            break
                        default:
                            captureException(
                                new Error(
                                    'Fatal HLS error encountered, destroying player'
                                ),
                                {
                                    errorType,
                                    errorDetails
                                }
                            )
                            // cannot recover
                            hls.destroy()
                            hlsRef.current = null
                            break
                    }

                    onErrorRef.current?.(
                        new CustomEvent(Hls.Events.ERROR, { detail: data })
                    )
                } else {
                    captureMessage('Non-fatal HLS Player error', {
                        errorType,
                        errorDetails
                    })
                }

                if (data.error) {
                    captureException(data.error, data)
                }
            })
        }

        return () => {
            hlsRef.current?.destroy()
            hlsRef.current = null
        }
    }, [src, onLoadedMetaDataRef, onErrorRef, starTimeRef, defaultTextTrackRef])

    useProperty('currentTime', videoRef, currentTime)
    useProperty('volume', videoRef, volume)

    const handleAbort = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onAbort?.(e?.nativeEvent)
        },
        [onAbort]
    )

    const handleCanPlay = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onCanPlay?.(e?.nativeEvent)
        },
        [onCanPlay]
    )

    const handleCanPlayThrough = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onCanPlayThrough?.(e?.nativeEvent)
        },
        [onCanPlayThrough]
    )

    const handleDurationChange = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onDurationChange?.(e?.nativeEvent)
        },
        [onDurationChange]
    )

    const handleEnded = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onEnded?.(e?.nativeEvent)
        },
        [onEnded]
    )

    const handleError = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onError?.(e?.nativeEvent)
        },
        [onError]
    )

    const handleLoadedData = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onLoadedData?.(e?.nativeEvent)
        },
        [onLoadedData]
    )

    const handleLoadStart = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onLoadStart?.(e?.nativeEvent)
        },
        [onLoadStart]
    )

    const handlePause = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onPause?.(e?.nativeEvent)
        },
        [onPause]
    )

    const handlePlay = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onPlay?.(e?.nativeEvent)
        },
        [onPlay]
    )

    const handlePlaying = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onPlaying?.(e?.nativeEvent)
        },
        [onPlaying]
    )

    const handleProgress = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onProgress?.(e?.nativeEvent)
        },
        [onProgress]
    )

    const handleRateChange = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onRateChange?.(e?.nativeEvent)
        },
        [onRateChange]
    )

    const handleSeeked = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onSeeked?.(e?.nativeEvent)
        },
        [onSeeked]
    )

    const handleSeeking = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onSeeking?.(e?.nativeEvent)
        },
        [onSeeking]
    )

    const handleStalled = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onStalled?.(e?.nativeEvent)
        },
        [onStalled]
    )

    const handleSuspend = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onSuspend?.(e?.nativeEvent)
        },
        [onSuspend]
    )

    const handleTimeUpdate = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onTimeUpdate?.(e?.nativeEvent)
        },
        [onTimeUpdate]
    )

    const handleVolumeChange = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onVolumeChange?.(e?.nativeEvent)
        },
        [onVolumeChange]
    )

    const handleWaiting = useCallback(
        (e: SyntheticEvent<HTMLVideoElement, Event>) => {
            onWaiting?.(e?.nativeEvent)
        },
        [onWaiting]
    )

    return (
        <video
            ref={videoRef}
            style={{ width: width ?? '100%', height: height ?? '100%' }}
            className={className}
            controls={controls}
            muted={muted}
            autoPlay={autoplay}
            loop={loop}
            preload={preloadValue}
            poster={poster}
            onAbort={handleAbort}
            onCanPlay={handleCanPlay}
            onCanPlayThrough={handleCanPlayThrough}
            onDurationChange={handleDurationChange}
            onEnded={handleEnded}
            onError={handleError}
            onLoadedData={handleLoadedData}
            onLoadStart={handleLoadStart}
            onPause={handlePause}
            onPlay={handlePlay}
            onPlaying={handlePlaying}
            onProgress={handleProgress}
            onRateChange={handleRateChange}
            onSeeked={handleSeeked}
            onSeeking={handleSeeking}
            onStalled={handleStalled}
            onSuspend={handleSuspend}
            onTimeUpdate={handleTimeUpdate}
            onVolumeChange={handleVolumeChange}
            onWaiting={handleWaiting}
        >
            {children}
        </video>
    )
}

export default React.memo(React.forwardRef(HLSPlayer))
