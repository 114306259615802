import { Box, SvgIcon, SvgIconProps, SxProps } from '@mui/material'
import {
    IconName,
    IconStyle,
    IconFamily
} from '@fortawesome/fontawesome-common-types'

export interface LeafIconProps extends SvgIconProps {
    icon: IconName
    sx?: SxProps & { color?: string }
    iconStyle?: IconStyle
    iconFamily?: IconFamily
}

const LeafIcon: React.FC<LeafIconProps> = ({
    icon,
    iconStyle = 'regular',
    iconFamily,
    color,
    sx,
    ...props
}) => {
    const iconClassName =
        'fa-' +
        iconStyle +
        ' fa-' +
        icon +
        (iconFamily ? ' fa-' + iconFamily : '')
    const { color: sxColor, ...restSx } = sx || {}
    if (sxColor || color) {
        // We apply the color to the span because of Windows OS native high contrast mode
        // If the color is applied to the svg directly, the icon will be treated as an image
        // and the high contrast mode will not be applied
        return (
            <Box
                component="span"
                sx={{
                    color: sxColor || color,
                    display: 'inline-flex',
                    alignItems: 'center'
                }}
            >
                <SvgIcon
                    {...props}
                    sx={restSx}
                    key={iconClassName.replace(' ', '-')}
                >
                    <i className={iconClassName} data-testid={'fa-icon'} />
                </SvgIcon>
            </Box>
        )
    }

    return (
        <SvgIcon {...props} sx={restSx} key={iconClassName.replace(' ', '-')}>
            <i className={iconClassName} data-testid={'fa-icon'} />
        </SvgIcon>
    )
}

export default LeafIcon
